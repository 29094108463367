* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.body {
    background-color: #151515;
    min-height: 100vh;
    max-width: 100vw;
    position: relative;
    margin: 0 auto;
    &__holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 6rem;

        &--article {
            width: 100%;
            position: relative;

            // background: linear-gradient(
            //     180deg,
            //     rgba(0, 0, 0, 1) 100%,
            //     rgba(0, 212, 255, 0) 88%
            // ) !important;
            // z-index: 1;
        }
    }

    &__name-holder {
        display: flex;
        justify-content: space-between;
        gap: 0.3rem;
        max-width: 100%;
        padding: 1rem;
        padding-top: 1.5rem;
    }

    &__image {
        width: 100%;
        max-height: 65vh;
        object-fit: cover;
        &--click {
            position: absolute;
            bottom: 0.5rem;
            color: rgba(255, 255, 255, 0.6);
            left: 0;
            text-align: center;
            width: 100%;
        }
    }

    &__common {
        font-size: 2.25rem;
        line-height: 100%;
        color: white;
        text-transform: capitalize;
        width: 100%;
    }

    &__scientific {
        font-size: 1.4rem;
        color: grey;
        font-style: italic;
        font-weight: 400;
        text-transform: capitalize;
    }

    &__location,
    &__name {
        font-size: 1rem;
        color: white;
        font-weight: 500;
        padding: 1rem;
    }

    &__name {
        margin-left: 1rem;
        padding: 0;
        text-decoration: none;
        max-width: fit-content;
        &--decoration {
            text-decoration: underline;
        }
    }

    &__button-handle {
        left: 0;
        bottom: 2rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        right: 0;

        padding: 0 1rem;
        &--back {
            background-color: transparent;

            &--icon {
                width: 2.5rem;
                height: 2.5rem;
                aspect-ratio: 1/1;

                // background-color: red;
            }
            // width: 2rem;
            // height: 100%;
            // width: fit-content;
            // padding-inline: 1rem;
            // aspect-ratio: 1/1;
            // background-color: #0f1f1d;
            // padding: auto;
            aspect-ratio: 1/1;
            max-width: 4.5rem;
            height: 4.5rem;
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            // height: 100%;
            border: 0px solid white;
            padding: 0.7rem;
            outline: 0;
            border-radius: 100%;
            transition: border 0.2s, bottom 0.2s, box-shadow 0.2s,
                background-color 0.3s;
            &:hover {
                background-color: #152c29;
                cursor: pointer;
                border: 2px solid white;
            }
        }
    }

    &__button {
        flex-grow: 1;
        width: calc(100% - 2rem);
        padding: 1rem 0;
        font-size: 1.6rem;
        background-color: #0f1f1d;
        color: #bcaf5d;
        margin-top: 6rem;
        margin-inline: auto;
        max-width: 1024px;
        position: fixed;
        bottom: 1.5rem;
        left: 0;
        right: 0;
        font-weight: bolder;
        // position: fixed;
        border: 1px solid white;
        text-align: center;
        text-decoration: none;
        box-shadow: 0px 0.4rem 0px 0px rgba(12, 12, 12, 0.7);
        transition: bottom 0.2s, box-shadow 0.2s, background-color 0.3s;
        &:active {
            bottom: 1.1rem;
            box-shadow: 0px 0rem 0px 0px rgba(12, 12, 12, 0.7);
        }
        &:hover {
            background-color: #152c29;
        }
    }
}

.root {
    max-width: 1024px;
    margin: 0 auto;
}

.footer {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // margin-block: 1rem;
    &__coffee,
    &__design {
        min-width: 100%;
        text-align: center;
        color: rgba(255, 255, 255, 0.4);
        font-size: 0.8rem;
        // padding-top: 0.4rem;
    }
    &__coffee {
        transition: color 0.3s;
        &:hover {
            color: #bebebe;
        }
    }
    &::after {
        position: absolute;
        content: "";
        top: -3.5pt;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.3) !important;
    }
}

@media only screen and (min-width: 992px) {
    .body {
        &__image {
            display: flex;
            width: fit-content;
            max-width: 100%;
            margin: 0 auto;
        }
        &__informations {
            display: flex;
            justify-content: flex-start;
            gap: 1rem;
            padding: 1rem 0;
        }

        &__name {
            margin-left: 0;
            width: 100%;
        }

        &__location {
            padding: 0;
            padding-right: 1rem;
        }
        // background-color: red;
    }
}
